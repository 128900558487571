main {
  margin: 4rem 0 6rem;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;


  a {
    display: block;
    font-size: 1.2rem;
    font-family: "Permanent Marker", sans;
    text-decoration: underline;
  }

}